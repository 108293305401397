import { googleMapsGeocode } from '@/libs/googleMaps/geocode';
import { arrayNotEmpty } from '@/utils/arrayNotEmpty';
import { getCityFromAddressComponents, getZipcodeFromAddressComponents } from '@/utils/googleMap';

export const googlePlacesAutocomplete = async (params) => {
    if (window?.google?.maps?.places == null) {
        console.error('Google Places API not loaded');
        return;
    }

    const defaultBounds = {
        north: 41.357632843118424, // NY north
        south: 38.928212038110374, // NJ south
        east: -73.8948829510295, // NJ east
        west: -80.52071966199662, // PA west
    };

    const autocompletService = new window.google.maps.places.AutocompleteService();
    let response = null;

    try {
        response = await new Promise((resolve, reject) => {
            window.gm_authFailure = function (e) {
                window.gm_authFailure = undefined;
                reject();
            };

            const request = autocompletService.getPlacePredictions({
                componentRestrictions: { country: 'us' },
                region: 'us',
                types: ['(cities)'],
                locationRestriction: defaultBounds,
                ...params,
            });

            if (!request) {
                reject();
            } else {
                request.then(resolve).catch(reject);
            }
        });
    } catch (e) {
        console.error('Places API error');
    }

    return response?.predictions;
};
/**
 * returns the geolocation object associated to the place id
 * @param {string} placeId
 */
export const getDetailsByPlaceId = async (
    placeId,
    ref = document.createElement('div'),
    isBrowserGeolocation = false,
) => {
    if (!placeId) {
        return;
    }

    if (window?.google?.maps?.places == null) {
        console.error('Google Places API not loaded');
        return;
    }

    const placeService = new window.google.maps.places.PlacesService(ref);
    let response = null;

    try {
        response = await new Promise((resolve, reject) => {
            window.gm_authFailure = function (e) {
                window.gm_authFailure = undefined;
                reject();
            };
            placeService.getDetails(
                {
                    placeId: placeId,
                    fields: ['geometry', 'address_components'],
                },
                (result, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        resolve({
                            zipCode: getZipcodeFromAddressComponents(result),
                            city: getCityFromAddressComponents(result),
                            lat: result.geometry?.location?.lat(),
                            lng: result.geometry?.location?.lng(),
                            isBrowserGeolocation,
                        });
                    }
                },
            );
        });
    } catch (e) {
        console.error('Places API error');
    }
    return response;
};

/**
 * returns the geolocation object associated to the place id
 * @param {string} placeId
 */
export const getGeolocationByPlaceId = async (placeId, isBrowserGeolocation = false) => {
    if (!placeId) {
        return;
    }
    try {
        const response = await googleMapsGeocode({ placeId: placeId });
        if (!arrayNotEmpty(response?.results)) {
            return null;
        }

        return {
            zipCode: getZipcodeFromAddressComponents(response),
            city: getCityFromAddressComponents(response),
            lat: response.results[0].geometry?.location?.lat(),
            lng: response.results[0].geometry?.location?.lng(),
            isBrowserGeolocation,
        };
    } catch (err) {
        console.error(err);
    }
};
